@vehicle-header-height: 55px;
@error-banner-height: 120px;

.vehicle-details {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  overflow: hidden;

  .vehicle-header {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    flex: unset;

    span {
      margin-bottom: unset;
    }
  }

  .vehicle-tabs {
    display: flex;
    flex: 1;
    overflow: auto;

    #inventory-vehicle-form-panel,
    #vehicle-cost-panel {
      margin-top: @vehicle-header-height;

      .vehicle-cost {
        padding: 1rem;
      }
    }
  }
}

#vin-conflict-modal {
  width: 50vw;
}
