@import '~@cdk-flex/components-styles/colors';

body {
  margin: 0;
}

input::-ms-clear {
  display: none;
}

.home-div {
  padding: 25px;
}

.center {
  text-align: center;
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.ag-overlay-loading-rows {
  padding-top: 100px;
}

.react-easy-print-print {
  height: 100%;
}

@media print {
  .menu-layout__content {
    overflow-y: unset !important;
  }

  .react-easy-print-print {
    height: unset;
  }
}

.flex-grid-wrapper {
  .ag-paging-button {
    position: relative;
  }
}
