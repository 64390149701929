.loading-animation {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 5000;

  &.large {
    .cdk-circular-progress,
    .cdk-circular-progress--circle {
      width: 60px;
      height: 60px;
      &--indeterminate__inner {
        width: 60px;
        height: 60px;
      }
      &--indeterminate__left-half,
      &--indeterminate__right-half {
        width: 30px;
        height: 60px;
      }
    }
  }

  .cdk-circular-progress {
    position: relative;
    top: 40%;
    transform: translateY(-40%);
  }
}
