.component__loader {
  padding: 10px 10px;
  text-align: center;
}

.loading-animation {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 5000;
}
.loading-animation.large .cdk-circular-progress,
.loading-animation.large .cdk-circular-progress--circle {
  width: 60px;
  height: 60px;
}
.loading-animation.large .cdk-circular-progress--indeterminate__inner,
.loading-animation.large .cdk-circular-progress--circle--indeterminate__inner {
  width: 60px;
  height: 60px;
}
.loading-animation.large .cdk-circular-progress--indeterminate__left-half,
.loading-animation.large .cdk-circular-progress--circle--indeterminate__left-half,
.loading-animation.large .cdk-circular-progress--indeterminate__right-half,
.loading-animation.large .cdk-circular-progress--circle--indeterminate__right-half {
  width: 30px;
  height: 60px;
}
.loading-animation .cdk-circular-progress {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
}

.app-link-card {
  text-decoration: none !important;
  font-style: normal !important;
  -webkit-text-decoration-style: none !important;
          text-decoration-style: none !important;
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
}
.app-link-card .avatar {
  padding: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
}
.app-link-card .app-text {
  padding: 20px;
  margin-left: 60px;
  font-size: 30px;
  line-height: 30px;
}

@media (max-width: 992px) {
  .quick-links {
    display: none;
  }
}
.quick-links .quick-link {
  text-decoration: none !important;
  font-style: normal !important;
  -webkit-text-decoration-style: none !important;
          text-decoration-style: none !important;
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  cursor: pointer;
  background: none;
  border: none;
}

.homePageHeader.cdk-page-header {
  height: 278px;
  padding: 0px;
  position: relative;
  width: auto;
  z-index: 0;
}
.homePageHeaderCardMedia.mdc-card__media.mdc-card__media--16-9 {
  height: 278px;
  background-image: url(/static/media/hero_background.ea66e73b.png);
}
.homePageHeadline.mdc-typography--headline6.mdc-typography--no-margins {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  right: 1rem;
}
.homePageGreeting {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: -15px;
  left: -15px;
  width: 100.9%;
  height: 65px;
  padding-left: 15px;
  font-family: Roboto;
  font-size: 24px;
  line-height: 65px;
}

#drive-flex .dashboard.drive-flex-page-content {
  overflow: unset;
}
#drive-flex .dashboard-cards > .mdc-layout-grid__inner {
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}
@media (min-width: 1280px) {
  #drive-flex .dashboard-cards > .mdc-layout-grid__inner {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
@media (max-width: 992px) {
  #drive-flex .dashboard-cards > .mdc-layout-grid__inner {
    grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
  }
}
@media (max-width: 768px) {
  #drive-flex .dashboard-cards > .mdc-layout-grid__inner {
    grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
  }
}

#vehicle-list {
  position: relative;
}
#vehicle-list .overlay-wrapper {
  position: absolute;
  top: 250px;
  z-index: 1;
  padding: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 25%;
  right: 25%;
}
#vehicle-list .right-align {
  justify-content: flex-end;
}
#vehicle-list .right-align .ag-header-cell-label {
  justify-content: flex-end;
}
#vehicle-list .ag-header-cell-text {
  margin-right: 0;
}
#vehicle-list .title-in-possession-cell {
  padding: 5px 12px 5px 8px;
}
#vehicle-list .title-in-possession-cell.no {
  background: linear-gradient(-45deg, transparent 7px, #e0e0e0 0);
}
#vehicle-list .title-in-possession-cell.yes {
  color: #ffffff;
  background: linear-gradient(-45deg, transparent 7px, #004e9a 0);
}
#vehicle-list .empty-vehicle-history-list {
  padding: 20px;
}
#vehicle-list .empty-vehicle-history-list-title {
  padding-bottom: 20px;
}
#vehicle-list .empty-vehicle-history-list-subtitle span {
  font-weight: bold;
}
#vehicle-list .empty-vehicle-history-list-criteria li {
  margin-bottom: 10px;
  padding-left: 15px;
  margin-left: -5px;
}
#vehicle-list .empty-vehicle-history-list .vehicle-search-criteria {
  padding-left: 0px;
  padding-right: 0px;
}
#vehicle-list .vehicle-list-tabs {
  margin-left: 0;
}
#vehicle-list .mdc-button {
  padding: 0;
}
#vehicle-list .flex-grid-header .flex-grid-icons {
  display: flex;
  align-items: center;
}
.vehicle-update .vehicle-title {
  float: left;
}
.vehicle-update .vehicle-error-messages .cdk-system-message {
  margin: 0;
}
.vehicle-update .vehicle-error-messages .cdk-system-message .cdk-system-message__content {
  justify-content: center;
}
.vehicle-update .mdc-dialog__surface .mdc-dialog__body {
  overflow: auto;
}
.vehicle-update .vehicle-title-status {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 30px;
}
.vehicle-update form.vehicle-form {
  padding: 30px;
}
.vehicle-update form.vehicle-form > .form-button {
  display: none;
}

.vehicle-details {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  overflow: hidden;
}
.vehicle-details .vehicle-header {
  display: flex;
  align-items: center;
  padding: 0px 16px;
  flex: unset;
}
.vehicle-details .vehicle-header span {
  margin-bottom: unset;
}
.vehicle-details .vehicle-tabs {
  display: flex;
  flex: 1 1;
  overflow: auto;
}
.vehicle-details .vehicle-tabs #inventory-vehicle-form-panel,
.vehicle-details .vehicle-tabs #vehicle-cost-panel {
  margin-top: 55px;
}
.vehicle-details .vehicle-tabs #inventory-vehicle-form-panel .vehicle-cost,
.vehicle-details .vehicle-tabs #vehicle-cost-panel .vehicle-cost {
  padding: 1rem;
}
#vin-conflict-modal {
  width: 50vw;
}

body {
  margin: 0;
}
input::-ms-clear {
  display: none;
}
.home-div {
  padding: 25px;
}
.center {
  text-align: center;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-overlay-loading-rows {
  padding-top: 100px;
}
.react-easy-print-print {
  height: 100%;
}
@media print {
  .menu-layout__content {
    overflow-y: unset !important;
  }
  .react-easy-print-print {
    height: unset;
  }
}
.flex-grid-wrapper .ag-paging-button {
  position: relative;
}

